import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
export default {
  name: "layout",
  inject: ["reload"],
  data() {
    const menuList = [{
      "name": "Home",
      "path": "/"
    }, {
      "name": "Statistics",
      "path": "/stat"
    }, {
      "name": "Referral",
      "path": "/ref"
    }, {
      "name": "User Referral",
      "path": "/uref"
    }, {
      "name": "Task",
      "path": "/task"
    }];
    return {
      menuList,
      selectItem: "",
      unsubscribe: null
    };
  },
  computed: {
    ...walletVuex.mapState(["isMobile"]),
    ...walletVuex.mapGetters(["isWallet"]),
    route() {
      return this.$route.path;
    }
  },
  watch: {},
  methods: {
    onMenu(item) {
      this.selectItem = item;
      this.$router.push(item.path);
    }
  },
  mounted() {
    this.menuList.map(item => {
      if (item.path == this.$route.path) {
        this.selectItem = item;
      }
    });
  }
};