var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "layout",
    class: _vm.isMobile && 'wap'
  }, [_vm.route !== '/login' ? [_c('div', {
    staticClass: "layout-menu"
  }, _vm._l(_vm.menuList, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "menu-item",
      class: _vm.route == item.path && 'active',
      on: {
        "click": function ($event) {
          return _vm.onMenu(item);
        }
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 0)] : _vm._e(), _c('div', {
    staticClass: "layout-main"
  }, [_c('div', {
    staticClass: "layout-main-title"
  }, [_vm._v("Ponyverse " + _vm._s(_vm.selectItem.name))]), _c('router-view')], 1)], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };